import type { FC } from 'react';

import {
  Content as CommonContent,
  type Props as CommonContentProps,
} from 'src/components/common/entry/common/content';
import { RichText } from 'src/components/common/rich-text';
import type { Props as RichTextProps } from 'src/components/common/rich-text/rich-text-renderer';

export type Props = CommonContentProps & {
  className?: string;
  content?: RichTextProps['document'] | null;
};

export const Content: FC<Props> = ({
  className,
  imageAlt,
  image,
  meta,
  content,
}) => (
  <CommonContent
    className={className ?? ''}
    image={image}
    imageAlt={imageAlt}
    meta={meta}
  >
    {content?.raw && <RichText document={content} />}
  </CommonContent>
);

export default Content;
