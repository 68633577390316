import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo, type FC } from 'react';

import { Anchor } from 'src/components/common/atoms';
import type { Props as RichTextProps } from 'src/components/common/rich-text/rich-text-renderer';
import { media } from 'src/styles';
import { Content as ArchiveContent } from './archive';
import type { Props as ContentProps } from './common/content';
import { Content as SingleContent } from './single';

type Props = Omit<ContentProps, 'imageAlt'> & {
  className?: string;
  name?: string;
  isSingle?: boolean;
  title?: string | null;
  postType?: string;
  date?: string | null;
  to?: string;
  content?: RichTextProps['document'] | null;
};

const useCustomTitle = ({
  title,
  postType,
}: Pick<Props, 'title' | 'postType'>) =>
  useMemo(() => {
    if (!postType) {
      return title;
    }
    return (
      <>
        <PostType>{postType}</PostType>
        {title}
      </>
    );
  }, [title, postType]);

export const Entry: FC<Props> = ({
  className,
  name,
  isSingle = true,
  title,
  postType,
  date,
  to,
  ...contentProps
}) => {
  const { meta, content } = contentProps;
  const customTitle = useCustomTitle({ title, postType });
  return (
    <Wrapper
      id={name ?? ''}
      className={className ?? ''}
      as={isSingle ? 'div' : 'article'}
    >
      {title && (
        <Header as={isSingle ? 'div' : 'header'}>
          <Title as={isSingle ? 'h1' : 'h2'}>
            {isSingle && customTitle}
            {!isSingle && (
              <>
                {!to && customTitle}
                {to && <StyledAnchor to={to}>{customTitle}</StyledAnchor>}
              </>
            )}
          </Title>
          {date && <Time>{date}</Time>}
        </Header>
      )}
      {(content || meta) && (
        <>
          {isSingle && (
            <StyledSingleContent imageAlt={title} {...contentProps} />
          )}
          {!isSingle && (
            <StyledArchiveContent imageAlt={title} {...contentProps} />
          )}
        </>
      )}
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

const PostType = styled.span`
  margin-right: 0.7em;
`;

const Title = styled.h2`
  line-height: 1.6;
  font-size: ${({ theme }) => theme.font.size.mediumLarge};
  ${media.greaterThanSiteBreakpoint} {
    font-size: ${({ theme }) => theme.font.size.large};
  }
`;

const Time = styled.time`
  min-width: 6.5em;
  text-align: right;
  line-height: 1.6;
  font-size: ${({ theme }) => theme.font.size.small};
  ${media.greaterThanSiteBreakpoint} {
    font-size: ${({ theme }) => theme.font.size.large};
  }
`;

const Header = styled.div`
  --border-position-top: -9px;
  --border-width: 30px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: var(--border-position-top);
    left: 0;
    display: block;
    width: var(--border-width);
    height: 0;
    border-bottom: 1px solid black;
  }
  ${media.greaterThanSiteBreakpoint} {
    --border-position-top: -16px;
    --border-width: 60px;
  }
`;

const ContentStyle = css`
  margin-top: 1.2em;
  ${media.greaterThanSiteBreakpoint} {
    margin-top: 60px;
  }
`;

const StyledSingleContent = styled(SingleContent)`
  ${ContentStyle}
`;

const StyledArchiveContent = styled(ArchiveContent)`
  ${ContentStyle}
`;

const Wrapper = styled.div``;

export default Entry;
