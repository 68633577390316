import type { HeaderMenuType } from 'src/types/custom-types';

export const DEFAULT_MENUS = [
  {
    name: 'news',
    to: '/news/',
    label: 'NEWS',
  },
  {
    name: 'shop',
    to: '/shop/',
    label: 'SHOP',
  },
  {
    name: 'gallery',
    to: '/gallery/',
    label: 'GALLERY',
  },
  {
    name: 'ikkaku',
    to: '/ikkaku/',
    label: '一画',
  },
  {
    name: 'online-store',
    to: 'https://yaecaya.yaeca.com',
    label: 'ONLINE STORE',
  },
] as HeaderMenuType[];
