import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState, type FC } from 'react';

import { media, theme as styleTheme } from 'src/styles';
import { Navigation } from './navigation';
import { Title } from './title';

type Props = {
  className?: string;
  isHome?: boolean;
};

const useIsInited = () => {
  const [isInited, setIsInited] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsInited(true);
      clearTimeout(timerId);
    }, 1000);
  }, []);
  return isInited;
};

const useIsHomeTop = ({ isHome }: Pick<Props, 'isHome'>) => {
  const [isHomeTop, setIsHomeTop] = useState(isHome ? true : false);
  useEffect(() => {
    if (isHome) {
      const onScroll = () => {
        const { top } = document.body.getBoundingClientRect();
        if (top < -200) {
          setIsHomeTop(false);
        } else {
          setIsHomeTop(true);
        }
      };
      setIsHomeTop(isHome ? true : false);
      onScroll();
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [isHome]);
  return { isHomeTop };
};

export const SiteHeader: FC<Props> = ({ className, isHome }) => {
  const isInited = useIsInited();
  const { isHomeTop } = useIsHomeTop({ isHome });
  return (
    <Wrapper className={`${className ?? ''}`} role="banner">
      <ThemeProvider theme={styleTheme}>
        <StyledTitle
          className={`${isHome ? 'is-home' : ''} ${
            isHome && isHomeTop ? 'is-home-top' : ''
          } ${isInited ? 'is-inited' : ''}`}
          headingTag={isHome ? 'h1' : 'p'}
        />
        <StyledNavigation
          className={`${isHome ? 'is-home' : ''} ${
            isHome && isHomeTop ? 'is-home-top' : ''
          }`}
        />
      </ThemeProvider>
    </Wrapper>
  );
};

const StyledTitle = styled(Title)`
  width: 130px;
  margin-left: auto;
  margin-right: auto;
  ${media.greaterThanSiteBreakpoint} {
    width: 175px;
  }
  &.is-inited {
    transition: 0.15s ease-out;
  }
  &.is-home {
    &.is-home-top {
      margin-top: 15px;
      width: 225px;
      ${media.iphoneVerticalOrLess} {
        /* NOTE:
        iphone5/SEはロゴを小さくする。
        */
        width: 170px;
      }
      ${media.greaterThanSiteBreakpoint} {
        margin-top: clamp(
          30px,
          ${({ theme }) => theme.structure.home.margin.top.pc},
          80px
        );
        width: 400px;
      }
    }
  }
`;

const StyledNavigation = styled(Navigation)`
  margin-top: 25px;
  ${media.greaterThanSiteBreakpoint} {
    margin-top: 45px;
  }
  &.is-home {
    &.is-home-top {
      ${media.greaterThanSiteBreakpoint} {
        margin-top: clamp(30px, 9vh, 75px);
      }
    }
  }
`;

const Wrapper = styled.header`
  line-height: 1;
  pointer-events: none;
`;

export default SiteHeader;
