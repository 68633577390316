import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';

import { media } from 'src/styles';

type Props = {
  className?: string;
  id?: string;
  subContent?: ReactNode;
  children?: ReactNode;
};

export const SiteContent: FC<Props> = ({
  className,
  id,
  subContent,
  children,
}) => (
  <Wrapper id={id ?? ''} className={className ?? ''}>
    <SubContent>
      {subContent && <SubContentInner>{subContent}</SubContentInner>}
    </SubContent>
    <MainContent>{children && children}</MainContent>
  </Wrapper>
);

const SubContentInner = styled.div`
  /* NOTE:
  position sticky用のdiv
   */
  ${media.greaterThanSiteBreakpoint} {
    position: sticky;
    top: 110px;
  }
`;

const SubContent = styled.div`
  ${media.greaterThanSiteBreakpoint} {
    padding-top: 4px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const MainContent = styled.div`
  ${SubContent} + & {
    ${media.siteBreakpointOrLess} {
      margin-top: 50px;
    }
  }
  ${media.greaterThanSiteBreakpoint} {
    grid-column-start: 3;
    grid-column-end: 7;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid black;
  padding-top: 22px;
  ${media.siteBreakpointOrLess} {
    max-width: 650px;
  }
  ${media.greaterThanSiteBreakpoint} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${({ theme }) => theme.structure.site.margin.horizontal.pc}%;
    padding-top: 90px;
  }
`;

export default SiteContent;
