import styled from '@emotion/styled';
import type { FC } from 'react';

import { DEFAULT_MENUS } from 'src/constants';
import { MenuList } from './menu-list';

type Props = {
  className?: string;
};

export const Navigation: FC<Props> = ({ className }) => (
  <Wrapper role="navigation" className={className || ''}>
    <MenuList menus={DEFAULT_MENUS} />
  </Wrapper>
);

const Wrapper = styled.nav`
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.english};
  font-size: ${({ theme }) => theme.font.size.mediumLarge};
  font-weight: bold;
  letter-spacing: ${({ theme }) => theme.letterSpacing.menu};
`;

export default Navigation;
