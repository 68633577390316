import styled from '@emotion/styled';
import type { FC } from 'react';

import { ScreenReaderText } from 'src/components/common';
import { Anchor } from 'src/components/common/atoms';
import LogoSVG from 'src/images/logo.svg';

type Props = {
  className?: string;
  headingTag?: 'h1' | 'p';
};

export const Title: FC<Props> = ({ className, headingTag = 'h1' }) => {
  return (
    <Wrapper className={`${className || ''}`}>
      <Inner as={headingTag}>
        <StyledAnchor to="/" lang="en">
          <ScreenReaderText>YAECA</ScreenReaderText>
          <LogoSVG />
        </StyledAnchor>
      </Inner>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  pointer-events: auto;
  text-decoration: none;
  display: block;
  transform: translateX(5.5%);
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Inner = styled.h1`
  font-weight: normal;
`;

const Wrapper = styled.div``;

export default Title;
