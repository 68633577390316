import styled from '@emotion/styled';
import { type FC } from 'react';

import { media } from 'src/styles';
import { Item, Props as ItemProps } from './item';

type Props = {
  className?: string;
  menus: ItemProps[];
};

export const MenuList: FC<Props> = ({ className, menus }) => (
  <Wrapper className={className || ''}>
    {menus.map((menu, index) => (
      <Item key={`header-menu-${index}`} {...menu} />
    ))}
  </Wrapper>
);

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  ${media.greaterThanSiteBreakpoint} {
    gap: 2em;
  }
  // NOTE:
  // Safari 14.1以下ではdisplay: flexのgapが効かないので、
  // Safari 14.0.x だけに適用される CSSハックを使う
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (not (translate: none)) {
      display: block;
      margin-left: -1em;
      margin-right: -1em;
      & > li {
        display: inline-block;
        margin-left: 1em;
        margin-right: 1em;
      }
    }
  }
`;

export default MenuList;
