exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gallery-contentful-gallery-slug-tsx": () => import("./../../../src/pages/gallery/{ContentfulGallery.slug}.tsx" /* webpackChunkName: "component---src-pages-gallery-contentful-gallery-slug-tsx" */),
  "component---src-pages-ikkaku-tsx": () => import("./../../../src/pages/ikkaku.tsx" /* webpackChunkName: "component---src-pages-ikkaku-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-contentful-news-slug-tsx": () => import("./../../../src/pages/news/{ContentfulNews.slug}.tsx" /* webpackChunkName: "component---src-pages-news-contentful-news-slug-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-templates-gallery-archive-tsx": () => import("./../../../src/templates/gallery-archive.tsx" /* webpackChunkName: "component---src-templates-gallery-archive-tsx" */),
  "component---src-templates-news-archive-category-tsx": () => import("./../../../src/templates/news-archive-category.tsx" /* webpackChunkName: "component---src-templates-news-archive-category-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "slice---src-components-slices-site-footer-index-tsx": () => import("./../../../src/components/slices/site-footer/index.tsx" /* webpackChunkName: "slice---src-components-slices-site-footer-index-tsx" */),
  "slice---src-components-slices-site-header-index-tsx": () => import("./../../../src/components/slices/site-header/index.tsx" /* webpackChunkName: "slice---src-components-slices-site-header-index-tsx" */)
}

