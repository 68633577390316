import type { FC, ReactNode } from 'react';
import { Link } from 'react-scroll';

import { theme } from 'src/styles';
import { useIsMobile } from 'src/utils';

type Props = {
  className?: string;
  children: ReactNode;
  to: string;
  ['aria-label']?: string;
  onClick?(): void;
  scrollOffset?: number;
};

export const ScrollAnchor: FC<Props> = ({
  className,
  children,
  to,
  'aria-label': ariaLabel,
  onClick,
  scrollOffset,
}) => {
  const { scrollOffset: defaultScrollOffset } = theme;
  const isMobile = useIsMobile();
  const withoutHashTo = to.substring(1);
  return (
    <Link
      className={className ?? ''}
      to={withoutHashTo}
      aria-label={ariaLabel}
      smooth={true}
      duration={300}
      onClick={onClick ?? undefined}
      offset={
        scrollOffset
          ? scrollOffset
          : defaultScrollOffset
          ? isMobile
            ? -defaultScrollOffset.sp
            : -defaultScrollOffset.pc
          : undefined
      }
    >
      {children}
    </Link>
  );
};

export default ScrollAnchor;
