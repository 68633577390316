import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';

import { media } from 'src/styles';

type Props = {
  className?: string;
  children: ReactNode;
};

export const SubMenuList: FC<Props> = ({ className, children }) => (
  <Wrapper className={className ?? ''}>{children}</Wrapper>
);

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  font-family: ${({ theme }) => theme.font.family.english};
  font-size: ${({ theme }) => theme.font.size.medium};
  li {
    line-height: 1;
    ${media.greaterThanSiteBreakpoint} {
      line-height: 1.4;
    }
  }
  a {
    text-decoration: none;
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
  > li + li {
    ${media.greaterThanSiteBreakpoint} {
      margin-top: 1.5em;
    }
  }
`;

export default SubMenuList;
