import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';

import { media } from 'src/styles';
import { Pagination } from './pagination';

type Props = {
  id?: string;
  className?: string;
  children?: ReactNode;
  pageInfo?: Queries.PaginationFragment;
  archivePath?: string;
};

export const Entries: FC<Props> = ({
  id,
  className,
  children,
  pageInfo,
  archivePath,
}) => (
  <Wrapper id={id} className={className ?? ''}>
    {children}
    {pageInfo && archivePath && (
      <StyledPagination archivePath={archivePath} {...pageInfo} />
    )}
  </Wrapper>
);

const StyledPagination = styled(Pagination)`
  margin-top: 45px;
  ${media.greaterThanSiteBreakpoint} {
    margin-top: 120px;
  }
`;

const Wrapper = styled.div`
  > *:not(${StyledPagination}) {
    & + * {
      margin-top: 45px;
      ${media.greaterThanSiteBreakpoint} {
        margin-top: 90px;
      }
    }
  }
`;

export default Entries;
