import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';

import { media } from 'src/styles';
import {
  RichTextRenderer,
  type Props as RichTextProps,
} from './rich-text-renderer';

type Props = {
  className?: string;
  document: RichTextProps['document'];
  headerChildren?: ReactNode;
  footerChildren?: ReactNode;
};

export const RichText: FC<Props> = ({
  className,
  document,
  headerChildren,
  footerChildren,
}) => (
  <Wrapper className={className ?? ''}>
    {headerChildren && headerChildren}
    <RichTextRenderer document={document} />
    {footerChildren && footerChildren}
  </Wrapper>
);

const Wrapper = styled.div`
  * {
    & + * {
      margin-top: 2em;
    }

    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 2.5em;
    }

    & + li {
      margin-top: 0;
    }

    & + blockquote {
      margin-top: 2.5em;
    }

    blockquote + * {
      margin-top: 2.5em;
    }

    &:empty:not(br):not(p) {
      display: none;
    }
  }

  p:empty {
    height: 1px;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: bold;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    position: relative;
    font-style: italic;
    padding-left: 1.5em;
    padding-right: 1.5em;
    &::before,
    &::after {
      position: absolute;
      font-size: 1.6em;
      line-height: 1;
    }

    &::before {
      content: '“';
      top: 0;
      left: 0;
    }

    &::after {
      content: '”';
      bottom: 0;
      right: 0;
    }
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  /* NOTE:
  boldとunderlineを付けたものは
  強調ということにして、font-sizeを上げる。
   */
  b u,
  u b {
    font-size: ${({ theme }) => theme.font.size.large};
    text-decoration: none;
  }

  a.strong {
    text-decoration: none;
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  ${media.siteBreakpointOrMore} {
    h3,
    h4,
    h5,
    h6 {
      font-size: ${({ theme }) => theme.font.size.mediumLarge};
    }
  }
`;

export default RichText;
