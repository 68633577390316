import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import type { FC } from 'react';

import { Img } from 'src/components/common/atoms';
import { RichText } from 'src/components/common/rich-text';
import type { Props as RichTextProps } from 'src/components/common/rich-text/rich-text-renderer';
import { theme } from 'src/styles';

export type Props = {
  className?: string;
  imageAlt?: string | null;
  image?: Queries.EntryImageFragment | null;
  meta?: RichTextProps['document'] | null;
  children?: React.ReactNode;
};

export const imgFragment = graphql`
  fragment EntryImage on ContentfulAsset {
    ...Image
    gatsbyImageData(
      layout: FULL_WIDTH
      quality: 85
      breakpoints: [375, 565, 768]
    )
  }
`;

export const Content: FC<Props> = ({
  className,
  imageAlt,
  image,
  meta,
  children,
}) => (
  <Wrapper className={className ?? ''}>
    {image && (
      <Img
        image={image}
        alt={imageAlt ?? ''}
        sizes={`(min-width: ${
          theme.breakpoints.siteMaxWidth + 1
        }px) 565px, (min-width: ${
          theme.breakpoints.siteBreakpoint + 1
        }px) calc((100vw - ${
          theme.structure.site.margin.horizontal.sp * 2
        }) / 8 * 4 ), calc(100vw - ${
          theme.structure.site.margin.horizontal.sp * 2
        }px)`}
      />
    )}
    {meta?.raw && <RichText document={meta} />}
    {children && children}
  </Wrapper>
);

const Wrapper = styled.div`
  > * + * {
    margin-top: 2em;
  }
`;

export default Content;
