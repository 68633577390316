import { graphql } from 'gatsby';
import {
  GatsbyImage,
  getImage, type IGatsbyImageData
} from 'gatsby-plugin-image';
import type { ComponentProps, FC } from 'react';

export type Props =  Omit<ComponentProps<'img'>, 'onLoad'> & {
    className?: string;
    image: Queries.ImageFragment & {
      gatsbyImageData: IGatsbyImageData | null;
    }
    onLoad?: () => void;
  };

export const imgFragment = graphql`
  fragment Image on ContentfulAsset {
    title
    url
    mimeType
  }
`;

export const Img: FC<Props> = ( { className, image, onLoad, alt, title, loading = 'lazy',...restProps }) => {
  if (image?.mimeType.includes('gif') || image?.mimeType.includes('svg')) {
    return (
      <div className={`${className ?? ''} image-type-${image?.mimeType}`}>
        <img
          src={image?.url ?? ''}
          alt={alt || title || ''}
          loading={loading}
          onLoad={onLoad}
          {...restProps}
        />
      </div>
    );
  } else {
    const gatsbyImage = getImage(image);
    if (!gatsbyImage) {
      return null;
    }
    return <GatsbyImage image={gatsbyImage} alt={alt || title || ''} loading={loading} onLoad={onLoad} {...restProps}/>
  }
};

export default Img;
