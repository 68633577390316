import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import type { FC } from 'react';

import { Next } from './next';
import { Previous } from './previous';

type Props = Queries.PaginationFragment & {
  className?: string;
  archivePath: string;
};

export const fragment = graphql`
  fragment Pagination on PageInfo {
    hasPreviousPage
    hasNextPage
    currentPage
  }
`;

export const Pagination: FC<Props> = ({
  className,
  hasPreviousPage,
  hasNextPage,
  currentPage,
  archivePath,
}) => {
  if (!hasPreviousPage && !hasNextPage) {
    return null;
  }
  return (
    <Wrapper className={className ?? ''}>
      <Previous
        hasPreviousPage={hasPreviousPage}
        currentPage={currentPage}
        archivePath={archivePath}
      />
      <Next
        hasNextPage={hasNextPage}
        currentPage={currentPage}
        archivePath={archivePath}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
  font-size: ${({ theme }) => theme.font.size.small};
  > span {
    text-decoration: underline;
  }

  > a {
    text-decoration: none;
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
`;

export default Pagination;
