import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, type ComponentProps, type FC } from 'react';

import { useLocationContext } from 'src/components/common/providers/location-provider/location-provider';
import { stripTag } from 'src/utils';

type MetaProps = ComponentProps<'meta'>;
type Props = {
  title?: string | null;
  description?: string | null;
  meta?: MetaProps[];
  image?: string | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
  isSingle?: boolean;
  firstPublicationDate?: string;
  lastPublicationDate?: string;
};

const query = graphql`
  query GetSeo {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        twitter
        facebookAppId
        banner
        siteLanguage
      }
    }
  }
`;

function useAddViewportFitCover() {
  useEffect(() => {
    // NOTE:
    // viewport-fit=coverを追加する。
    // viewportのmetaは.caches/default-html.jsに直接書かれているので、
    // 上書きできないため、ここで無理やり上書きする。
    const viewportMeta = document.head.querySelector<HTMLMetaElement>(
      'meta[name="viewport"]'
    );
    if (
      viewportMeta &&
      !viewportMeta.classList.contains('added-viewport-fit')
    ) {
      const defaultViewportContent = viewportMeta.getAttribute('content');
      if (defaultViewportContent) {
        viewportMeta.setAttribute(
          'content',
          `${defaultViewportContent}, viewport-fit=cover`
        );
        viewportMeta.classList.add('added-viewport-fit');
      }
    }
  }, []);
}

export const SEO: FC<Props> = ({
  title,
  description,
  meta,
  image,
  imageWidth = 1200,
  imageHeight = 630,
}: // isSingle = false,
// firstPublicationDate,
// lastPublicationDate,
Props) => {
  const { pathname } = useLocationContext();
  const { site } = useStaticQuery<Queries.GetSeoQuery>(query);
  const defaultTitle = site?.siteMetadata?.title || '';
  const pageTitle = title ? `${title} - ${defaultTitle}` : defaultTitle || '';
  const defaultDescription = site?.siteMetadata?.description || '';
  const stripTaggedDescription = description ? stripTag(description) : null;
  const metaDescription =
    stripTaggedDescription?.replace(/\r?\n/g, '')?.substr(0, 450) ||
    defaultDescription ||
    '';
  const siteUrl = site?.siteMetadata?.siteUrl || '';
  const url = `${siteUrl}${pathname}`;
  const defaultBanner = site?.siteMetadata?.banner || '';
  const seoImage = image ? `${siteUrl}${image}` : `${siteUrl}/${defaultBanner}`;

  useAddViewportFitCover();

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: seoImage,
    },
    {
      property: `og:image:width`,
      content: imageWidth,
    },
    {
      property: `og:image:height`,
      content: imageHeight,
    },
    {
      property: `fb:app_id`,
      content: site?.siteMetadata?.facebookAppId,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site?.siteMetadata?.author,
    },
    {
      name: `twitter:title`,
      content: pageTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: seoImage,
    },
  ] as MetaProps[];

  const pageMeta = meta ? [...defaultMeta, ...meta] : defaultMeta;

  const schemaOrgJSONLD: any = [
    {
      '@context': 'http://schema.org',
      '@type': 'Corporation',
      '@id': siteUrl,
      url: siteUrl,
      name: defaultTitle,
      image: {
        '@type': 'ImageObject',
        url: `${siteUrl}/${defaultBanner}`,
      },
    },
  ];

  // if (isSingle) {
  //   const author = site?.siteMetadata?.author || '@';
  //   schemaOrgJSONLD = [
  //     {
  //       '@context': 'http://schema.org',
  //       '@type': 'BlogPosting',
  //       '@id': url,
  //       url: url,
  //       name: title || '',
  //       headline: title || '',
  //       image: {
  //         '@type': 'ImageObject',
  //         url: seoImage,
  //       },
  //       description: description,
  //       datePublished: firstPublicationDate || undefined,
  //       dateModified: lastPublicationDate || undefined,
  //       author: {
  //         '@type': 'Person',
  //         name: author,
  //       },
  //       publisher: {
  //         '@type': 'Organization',
  //         name: author,
  //         logo: {
  //           '@type': 'ImageObject',
  //           url: `${siteUrl}/icons/icon-512x512.png`,
  //         },
  //       },
  //       isPartOf: siteUrl,
  //       mainEntityOfPage: {
  //         '@type': 'LocalBusiness',
  //         '@id': siteUrl,
  //       },
  //     },
  //   ];
  // }

  return (
    <>
      <html lang={site?.siteMetadata?.siteLanguage ?? 'ja'} />
      <title>{pageTitle}</title>
      {pageMeta.map((item, index) => {
        if ('name' in item) {
          return <meta key={index} name={item.name} content={item.content} />;
        } else if ('property' in item) {
          return (
            <meta key={index} property={item.property} content={item.content} />
          );
        }
      })}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </>
  );
};

export default SEO;
