import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Slice, type PageProps } from 'gatsby';
import { useEffect, useState, type FC } from 'react';

import { Providers } from 'src/components/common';
import { Clock } from 'src/components/common/clock';
import { GlobalStyle, media } from 'src/styles';
import type { PageContextType } from 'src/types/custom-types';

type Props = PageProps<unknown, PageContextType>;

const useIsShown = () => {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsShown(true);
    }, 500);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return isShown;
};

const Layouts: FC<Props> = ({ location, children }) => {
  const { pathname } = location;
  const isHome = pathname === '/';
  const isShown = useIsShown();
  // NOTE:
  // gatsby-plugin-offlineを使用すると、
  // swインストール後、一旦fallbackページを経由する場合があり、
  // その際hydrationエラーが発生するため
  // fallbackページの場合はnullを返す
  // エラー内容
  // https://reactjs.org/docs/error-decoder.html/?invariant=418
  // https://reactjs.org/docs/error-decoder.html/?invariant=423
  if (pathname === '/offline-plugin-app-shell-fallback/') {
    return null;
  }
  return (
    <>
      <Global styles={GlobalStyle} />
      <Providers pathname={pathname}>
        <Page className={`${isShown ? 'is-shown' : ''}`}>
          {isHome && <StyledClock />}
          <StyledSiteHeaderWrapper className={isHome ? 'is-home' : ''}>
            <Slice alias="site-header" isHome={isHome} />
          </StyledSiteHeaderWrapper>
          <Main role="main" id="main" className={isHome ? 'is-home' : ''}>
            {children}
          </Main>
          <StyledFooterWrapperStyle>
            <Slice alias="site-footer" />
          </StyledFooterWrapperStyle>
        </Page>
      </Providers>
    </>
  );
};

const StyledClock = styled(Clock)`
  font-family: ${({ theme }) => theme.font.family.english};
  line-height: 1;
  font-weight: bold;
  position: absolute;
  top: 15px;
  left: 0;
  ${media.greaterThanSiteBreakpoint} {
    top: 30px;
  }
`;

const StyledSiteHeaderWrapper = styled.div`
  z-index: 1000;

  margin-top: ${({ theme }) => theme.structure.site.margin.top.sp}px;
  ${media.greaterThanSiteBreakpoint} {
    margin-top: ${({ theme }) => theme.structure.site.margin.top.pc}px;
  }
  &.is-home {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  &:not(.is-home) {
    position: sticky;
    --top: 10px;
    top: var(--top);
    ${media.greaterThanSiteBreakpoint} {
      --top: 20px;
    }
  }
`;

const Main = styled.main`
  flex-grow: 1;
  /* NOTE:
  mainの子要素にdisplay:flexがあり高さが伸びないので、
  ここでもdisplay:flexして、高さを揃える。
   */
  display: flex;
  &:not(.is-home) {
    margin-top: 30px;
    ${media.greaterThanSiteBreakpoint} {
      margin-top: 135px;
    }
  }
`;

const StyledFooterWrapperStyle = styled.div`
  margin-top: 60px;
  ${media.greaterThanSiteBreakpoint} {
    margin-top: 120px;
  }
`;

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: ${({ theme }) => theme.breakpoints.siteMaxWidth}px;
  margin-left: auto;
  margin-right: auto;
  line-height: ${({ theme }) => theme.lineHeight.sp};
  background-image: ${({ theme }) => theme.colors.bg};
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.base.sp};
  padding-left: ${({ theme }) => theme.structure.site.margin.horizontal.sp}%;
  padding-right: ${({ theme }) => theme.structure.site.margin.horizontal.sp}%;
  padding-bottom: ${({ theme }) => theme.structure.site.margin.bottom.sp}px;
  opacity: 0;
  transition: opacity 0.1s linear;
  &.is-shown {
    opacity: 1;
  }
  ${media.greaterThanSiteBreakpoint} {
    font-size: ${({ theme }) => theme.font.size.base.pc};
    line-height: ${({ theme }) => theme.lineHeight.pc};
    padding-left: ${({ theme }) => theme.structure.site.margin.horizontal.pc}%;
    padding-right: ${({ theme }) => theme.structure.site.margin.horizontal.pc}%;
    padding-bottom: ${({ theme }) => theme.structure.site.margin.bottom.pc}px;
  }
`;

export default Layouts;
