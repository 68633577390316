import styled from '@emotion/styled';
import { useCallback, useState, type FC } from 'react';

import { Button } from 'src/components/common/atoms';
import {
  Content as CommonContent,
  type Props as CommonContentProps,
} from 'src/components/common/entry/common/content';
import { RichText } from 'src/components/common/rich-text';
import type { Props as RichTextProps } from 'src/components/common/rich-text/rich-text-renderer';
import { ScreenReaderTextStyle } from 'src/components/common/screen-reader-text';

export type Props = CommonContentProps & {
  className?: string;
  content?: RichTextProps['document'] | null;
};

type UseIsShownContentProps = Pick<Props, 'meta'>;
const useIsShownContent = ({ meta }: UseIsShownContentProps) => {
  const [isShownContent, setIsShownContent] = useState(!meta ? true : false);
  const showContent = useCallback(() => {
    setIsShownContent(true);
  }, []);
  return { isShownContent, showContent };
};

export const Content: FC<Props> = ({
  className,
  imageAlt,
  image,
  meta,
  content,
}) => {
  const { isShownContent, showContent } = useIsShownContent({ meta });
  return (
    <CommonContent
      className={className ?? ''}
      image={image}
      imageAlt={imageAlt}
      meta={meta}
    >
      {content?.raw && (
        <>
          {!isShownContent && (
            <StyledButton aria-hidden={true} onClick={showContent}>
              more info
            </StyledButton>
          )}
          <StyledContent
            className={!isShownContent ? 'hide' : ''}
            document={content}
          />
        </>
      )}
    </CommonContent>
  );
};

const StyledButton = styled(Button)`
  margin-top: 2em;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

const StyledContent = styled(RichText)`
  &.hide {
    ${ScreenReaderTextStyle}
  }
`;

export default Content;
