import '@emotion/react';

export const theme = {
  font: {
    family: {
      english: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      main: '"游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
    },
    size: {
      base: { pc: '1.4rem', sp: '1.4rem' },
      xLarge: '2.0rem',
      large: '1.8rem',
      mediumLarge: '1.6rem',
      medium: '1.4rem',
      small: '1.2rem',
    },
  },
  letterSpacing: {
    main: '0.01em',
    menu: '0.07em',
  },
  lineHeight: { pc: 2, sp: 2 },
  colors: {
    bg: '#fff',
    font: '#000',
    gray: '#9FA0A0',
    lightGray: '#cecece',
  },
  structure: {
    site: {
      margin: {
        horizontal: {
          sp: (36 / 750) * 100, //%表示
          pc: (24 / 1500) * 100, //%表示
        },
        top: {
          sp: 36,
          pc: 60,
        },
        bottom: {
          sp: 23,
          pc: 45,
        },
      },
    },
    home: {
      margin: {
        top: {
          pc: '7vh',
        },
      },
    },
  },
  breakpoints: {
    siteBreakpoint: 768,
    iphoneSe2Vertical: 375,
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    siteMaxWidth: 1200,
    ipadProHorizontal: 1300,
  },
  scrollOffset: {
    sp: 125,
    pc: 160,
  },
};

type ThemeType = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeType {}
}

export default theme;
