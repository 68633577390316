import type {
  NodeRenderer,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Text } from '@contentful/rich-text-types';
import {
  renderRichText,
  type ContentfulRichTextGatsbyReference,
  type RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { useCallback, useMemo, type FC } from 'react';

import { Anchor } from 'src/components/common/atoms';

// NOTE:
// referensesが必須になっているが、
// 実際はreferensesが無いこともあるので、
// 型操作を行う。
//
type RichTextDocumenType =
  RenderRichTextData<ContentfulRichTextGatsbyReference>;
type CustomRichTextDocumentType = {
  raw: RichTextDocumenType['raw'] | null;
  readonly references?: Queries.Maybe<
    ReadonlyArray<Queries.Maybe<ContentfulRichTextGatsbyReference>>
  >;
};
export type Props = {
  document: CustomRichTextDocumentType;
};

const isTextType = (arg: any): arg is Text => arg?.nodeType === 'text';

// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/CHANGELOG.md#400-next0-2020-11-09
const useOptions = () => {
  const linkResolver = useCallback<NodeRenderer>((node, children) => {
    const { content, data } = node;
    let isStrong = false;
    if (content?.[0] && isTextType(content[0])) {
      const hasUnderline = content[0].marks.find(
        (mark) => mark.type === 'underline'
      );
      const hasBold = content[0].marks.find((mark) => mark.type === 'bold');
      if (hasUnderline && hasBold) {
        isStrong = true;
      }
    }

    return (
      <Anchor to={data.uri} className={isStrong ? 'strong' : ''}>
        {children}
      </Anchor>
    );
  }, []);

  const assetLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const { target } = node.data;
    const href = target?.file?.url as string | undefined;
    if (!href) {
      return;
    }
    return (
      <a href={`https:${href}`} target="_blank" rel="noopenner noreferrer">
        {children}
      </a>
    );
  }, []);

  return useMemo<Options>(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: linkResolver,
        [INLINES.ASSET_HYPERLINK]: assetLinkResolver,
      },
      // NOTE:
      // 改行を<br>に変換
      // https://github.com/contentful/rich-text/issues/96
      renderText: (text) =>
        text
          .split('\n')
          .flatMap((text, i) => [
            i > 0 && <br key={`line-break-${i}`} />,
            text,
          ]),
    }),
    [linkResolver, assetLinkResolver]
  );
};
export const RichTextRenderer: FC<Props> = ({ document }) => {
  const options = useOptions();
  if (!document?.raw) {
    return null;
  }
  return <>{renderRichText(document as RichTextDocumenType, options)}</>;
};

export default RichTextRenderer;
