import { createContext, useContext, type FC, type ReactNode } from 'react';

type Props = {
  pathname: string;
  children: ReactNode;
};

const initialValue = {
  slug: '',
  pathname: '',
  isHome: true,
};

type ContextType = typeof initialValue;

const Context = createContext<ContextType>(initialValue);

const getSlug = ({ pathname }: { pathname: string }) => {
  if (pathname === '/') {
    return 'home';
  } else {
    return pathname.replace(/^\/?([^/]+)\/?$/, '$1');
  }
};

export const LocationProvider: FC<Props> = ({ pathname, children }) => {
  const slug = getSlug({ pathname });
  return (
    <Context.Provider value={{ slug, pathname, isHome: slug === 'home' }}>
      {children}
    </Context.Provider>
  );
};

export const useLocationContext = () => {
  return useContext(Context);
};

export default LocationProvider;
