import type { FC } from 'react';

import { Anchor } from 'src/components/common/atoms';

type Props = Pick<Queries.PageInfo, 'hasNextPage' | 'currentPage'> & {
  className?: string;
  archivePath: string;
};

export const Next: FC<Props> = ({
  className,
  hasNextPage,
  currentPage,
  archivePath,
}) => {
  if (!hasNextPage) {
    return <span />;
  }
  return (
    <Anchor
      className={className ?? ''}
      to={`${archivePath}/page/${currentPage + 1}/`}
    >
      <span aria-label="Next page">Next</span>
    </Anchor>
  );
};

export default Next;
