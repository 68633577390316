import { ThemeProvider } from '@emotion/react';
import type { FC, ReactNode } from 'react';

import { theme as styleTheme } from 'src/styles';
import { LocationProvider } from './location-provider';

type Props = {
  pathname: string;
  children: ReactNode;
};

export const Providers: FC<Props> = ({ pathname, children }) => {
  return (
    <ThemeProvider theme={styleTheme}>
      <LocationProvider pathname={pathname}>{children}</LocationProvider>
    </ThemeProvider>
  );
};

export default Providers;
