import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useEffect, useState, type FC } from 'react';

import { media } from 'src/styles';

type Props = {
  className?: string;
};

const CLOCK_FORMAT = 'YYYY.MM.DD HH:mm';

const useClock = () => {
  const [clock, setClock] = useState('');
  useEffect(() => {
    setClock(dayjs().format(CLOCK_FORMAT));
    const timerId = setInterval(() => {
      setClock(dayjs().format(CLOCK_FORMAT));
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return clock;
};

export const Clock: FC<Props> = ({ className }) => {
  const clock = useClock();
  return <Wrapper className={className ?? ''}>{clock}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.font.family.english};
  font-size: 10px;
  ${media.greaterThanSiteBreakpoint} {
    font-size: 16px;
  }
`;

export default Clock;
