import styled from '@emotion/styled';
import type { FC } from 'react';

import { Anchor } from 'src/components/common/atoms';
import type { HeaderMenuType } from 'src/types/custom-types';

export type Props = HeaderMenuType & {
  className?: string;
};

export const Item: FC<Props> = ({ className, label, to }) => {
  if (!to) {
    return null;
  }
  return (
    <Wrapper className={className || ''}>
      <StyledAnchor to={to} activeClassName={'current'} partiallyActive={true}>
        {label}
      </StyledAnchor>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  pointer-events: auto;
  text-decoration: none;
  position: relative;
  &.current,
  &:hover,
  &:focus {
    text-decoration: underline;
    text-underline-offset: 7px;
    text-decoration-thickness: 1px;
  }
`;

const Wrapper = styled.li`
  line-height: 1;
`;

export default Item;
