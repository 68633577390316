import { css } from '@emotion/react';
import reset from 'emotion-reset';

import { media } from './media';
import { theme } from './theme';

export const GlobalStyle = css`
  ${reset}
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  ::selection {
    background: ${theme.colors.lightGray};
  }

  html {
    font-size: 62.5%;
    scroll-padding-top: ${theme.scrollOffset.sp}px;
    ${media.greaterThanSiteBreakpoint} {
      scroll-padding-top: ${theme.scrollOffset.pc}px;
    }
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.main};
    font-feature-settings: 'palt';
    letter-spacing: ${theme.letterSpacing.main};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    background-color: ${theme.colors.bg};
    line-height: ${theme.lineHeight.pc};
    font-family: inherit;
    font-size: inherit;
    border: 1px solid black;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover,
    &.focus-visible {
      text-decoration: none;
    }
    ${media.greaterThanSiteBreakpoint} {
      &[href^='tel:'] {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &:focus {
      outline: none;
    }
    &.focus-visible {
      outline: 1px solid black;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 1.5em;
  }

  table {
    border-collapse: collapse;
    line-height: 1.6;
    th,
    td {
      padding: 0.5em 1em;
      border: 1px solid black;
    }
    tr > td:first-of-type {
      min-width: 6.5em;
    }
  }
`;

export default GlobalStyle;
